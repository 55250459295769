
.glass-box {
    position: relative;
    width: 320px;
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 30px;
    transition: 0.5s;
    overflow: visible;
    -webkit-backface-visibility: hidden;
  }

  .glass-box:hover {
    position: relative;
    /* width: 420px; */
    /* height: 400px; */
    display: flex;
    justify-content: center;
    align-items: center;

    /* this is what can sometimes cause jitter */
    /* margin-left: 80px; */
    /* margin-top: 40px; */
    /* margin-bottom: 40px; */
    /* margin-right: 30px; */
    transition: 0.5s;
  }
  
  .glass-box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50px;
    right: 50px;
    
    background-color: #fff;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
  }
  
  /* .glass-box::after {
    content: '';
    position: absolute;
    top: 50px;
    left: 50px;
    right: 50px;
    width: 50%;
    height: 75%;
    background-color: #fff;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
    filter: blur(30px);
    transition: 0.5s;
    -webkit-backface-visibility: hidden;
    z-index: 0;
  } */

  .glass-box::after {
    margin-top: 20px;
    content: '';
    position: absolute;
    top: 25px;
    left: 50px;
    right: 50px;
    width: 55%;
    height: 85%;
    background-color: #fff;
    border-radius: 8px;
    transform: skewX(15deg);
    transition: 0.5s;
    filter: blur(30px);
    z-index: 0;
    /* -webkit-transform: skewX(15deg); */
    -webkit-transform:translate3d(0,0,0);
}
  
  /* .glass-box:hover::before,
  .glass-box:hover::after  {
    transform: skewX(0deg);
    left: 20px;
    width: calc(100%-90px);
  }  */
  
  .glass-card-container .glass-box:nth-child(1):before,
  .glass-card-container .glass-box:nth-child(1)::after {
    background: linear-gradient(315deg, #ffbc00, #ff0058);
  }
  
  .glass-card-container .glass-box:nth-child(2):before,
  .glass-card-container .glass-box:nth-child(2)::after {
    background: linear-gradient(315deg, #03a9f4, #ff0058);
  }
  
  .glass-card-container .glass-box:nth-child(3):before,
  .glass-card-container .glass-box:nth-child(3)::after {
    background: linear-gradient(315deg, #4dff03, #00d0ff);
  }

  .glass-card-container .glass-box:nth-child(6):before,
  .glass-card-container .glass-box:nth-child(6)::after {
    background: linear-gradient(315deg, #ffbc00, #ff0058);
  }
  
  .glass-card-container .glass-box:nth-child(4):before,
  .glass-card-container .glass-box:nth-child(4)::after {
    background: linear-gradient(315deg, #03a9f4, #ff0058);
  }
  
  .glass-card-container .glass-box:nth-child(5):before,
  .glass-card-container .glass-box:nth-child(5)::after {
    background: linear-gradient(315deg, #4dff03, #00d0ff);
  }

  .glass-card-container .glass-box:nth-child(7):before,
  .glass-card-container .glass-box:nth-child(7)::after {
    background: linear-gradient(315deg, #ffbc00, #ff0058);
  }
  
  .glass-card-container .glass-box:nth-child(8):before,
  .glass-card-container .glass-box:nth-child(8)::after {
    background: linear-gradient(315deg, #03a9f4, #ff0058);
  }
  
  .glass-card-container .glass-box:nth-child(9):before,
  .glass-card-container .glass-box:nth-child(9)::after {
    background: linear-gradient(315deg, #4dff03, #00d0ff);
  }
  
  .glass-card-container .glass-box span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    pointer-events: none;
  }
  
  .glass-card-container .glass-box span::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: rgba(255,255,255,0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate-card 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
  }
  
  .glass-card-container .glass-box:hover span::before {
    top: -80px;
    left: 50px;
    width: 100px;
    height: 100px;
    opacity: 1;
  }
  
  .glass-card-container .glass-box span::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    background: rgba(255,255,255,0.1);
    backdrop-filter: blur(10px);
    opacity: 0;
    transition: 0.5s;
    animation: animate-card 2s ease-in-out infinite;
    box-shadow: 0 5px 15px rgba(0,0,0,0.05);
    animation-delay: -1s;
  }
  
  
  .glass-card-container .glass-box:hover span::after {
    bottom: -95px;
    right: 50px;
    width: 100px;
    height: 100px;
    opacity: 1;
    position: absolute;
    z-index: -1 !important;
  }
  
  @keyframes animate-card {
    0%,100% {
      transform: translateY(10px);
    }
    50% {
      transform: translateY(-10px);
    }
  }
  
  
  
  
  .glass-card-container .glass-box .glass-box-content {
    position: relative;
    left: 0;
    padding: 30px 40px;
    background: rgba(255,255,255,0.05);
    box-shadow: 0 5px 15px rgba(0,0,0,0.1);
    border-radius: 20px;
    backdrop-filter: blur(10px);
    z-index: 1;
    transform: 0.5s;
    color: #fff;
  }
  
  .glass-card-container .glass-box:hover .glass-box-content {
    left: -25px;
    padding: 60px 40px;
  }
  
  .glass-card-container .glass-box .glass-box-content h2 {
    font-size: 2em;
    color: #fff;
    margin-bottom: 10px;
    /* padding-left: 0px; */
    padding-right: 15px;
    /* padding: 0px 6px; */
}
  
  .glass-card-container .glass-box .glass-box-content p {
    font-size: 1.1em;
    margin-bottom: 0.4em;
    line-height: 1.4em;
  }
  
  .glass-card-container .glass-box .glass-box-content a {
    /* display: inline-block;
    font-size: 1.1em;
    color: #111;
    background: none;
    padding:10px;
    border-radius: 10px;
    text-decoration: none; */


  /* background: linear-gradient(120deg, #f37fca 0%, #8ec5fc); */
  background: none;
  padding: 25px auto;
  text-align: center;
  /* margin: 6em 0em; */
  min-width: max-content;
  color: white;
  }

  
  
  .glass-academic-header {
    /* position: absolute;
    top: -40px;
    right: 20px;
    left: 20px; */
    margin-bottom: 0px;
    margin-top: 50px;
    margin-left: 30px;
    margin-right: 30px;
    /* margin:50px 30px; */
    /* padding: 20px 50px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: rgba(255,255,255,0.2); */
    /* background: #fff; */
    /* box-shadow: 0 15px 35px rgba(0,0,0,0.05); */
    border-radius: 20px;
    backdrop-filter: blur(10px);
    font-family: 'Poppins';
    color: #fff;
    /* border: 1px solid rgba(255,255,255,0.25); */
    text-align: center;
  }
  
  .glass-academic-header h2 {
    /* position: absolute;
    top: -40px;
    right: 20px;
    left: 20px; */
    font-family: 'Poppins';
    color: #fff;
  
  
    margin-top:50px;
    padding: 20px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background: rgba(255,255,255,0.2); */
    /* box-shadow: 0 15px 35px rgba(0,0,0,0.05); */
    border-radius: 20px;
    backdrop-filter: blur(10px);
    /* border: 1px solid rgba(255,255,255,0.25); */
  }

  .decorative-line {
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    opacity: 80%;
    border-radius: 20px;
  }

  .course {
    background: rgba(255,255,255,0.2);
    box-shadow: 0 15px 35px rgba(0,0,0,0.05);
    border-radius: 20px;
  }

  .course p {
    font-size: 0.5em;
  }

  .course-button {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    /* margin-bottom: 20px; */
  }

  .course-text {
    font-size: 0.9em !important;
    padding: 8px 15px;
    border-radius: 25px;
    backdrop-filter: blur(10px);
    box-shadow: 0 5px 10px rgba(0,0,0,0.15);
    background: rgba(255,255,255,0.05);
    border: 1px solid rgba(255,255,255,0.25);
    font-family: 'Consolas';
    color: white !important;
  }
