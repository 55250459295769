.mobile-landing-section {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 86vh;
    /* background: url(https://images.pexels.com/photos/7135057/pexels-photo-7135057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2);  */
    /* background-size: cover; */
    /* background-position: auto; */
    /* background-attachment: auto; */

    padding: 19px;
    /* background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%); */
    flex-direction: column;

    position: relative;
    overflow: hidden;
}



.mobile-landing-section .glass-personal-header .h2 {
    font-family: 'Poppins';
    color: #111;
    margin-top: 50px;
    padding: 0px 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 20px;
    backdrop-filter: blur(10px);
}

.mobile-landing-box-content {
    font-family: 'Consolas';
    position: relative;
    /* left: -25px; */
    padding: 55px 37px;
    padding-bottom: 33px;
    background: rgba(255,255,255,0.2);
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    border-radius: 20px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 1;
    transform: 0.5s;
    color: #fff;
    /* margin-bottom: 74px; */
    height: 40%;
    /* margin: 10px 217px; */
    padding-top: 11%;
    /* padding-bottom: 5%; */
    /* margin-top: 4%; */
}

.mobile-landing-box-content p {
    font-size: calc(0.5vw + 1vh + 2vmin);
    /* margin-bottom: 1.5em; */
    line-height: 1.4em;
    color: #2a163f;
    font-family: 'Consolas';
    font-weight: 400;
}

.mobile-landing-icon {
    max-width: 307px;
    /* margin-bottom: -4px; */
    /* display: flex; */
}

.mobile-about-box-content {
    font-family: Poppins;
    position: relative;
    /* left: -25px; */
    /* padding: 55px 37px; */
    background: rgba(255,255,255,0.2);
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    border-radius: 20px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 1;
    transform: 0.5s;
    color: #fff;
    width: 100%;
    padding-top: 95px;
    padding-left: 37px;
    padding-right: 37px;
    padding-bottom: 15px;
}

.mobile-landing-icon .mobile-img {
    position: relative;
    max-height: 180px;
    /* z-index: 600; */
    margin-left: 159px;
    margin-bottom: -223px;
    /* margin-right: -39px; */
    /* margin-top: -13px; */
    animation: animate-emoji 2s linear infinite;
    -webkit-transform: translate3d(0,0,0);
    z-index: 1;
    top: 16px;
}

.mobile-personal-header-wrapper {
    display: flex;
    margin-left: 13px;
    margin-bottom: -25px;
    min-width: 20vh;
}

.mobile-personal-proj-title {
    position: relative;
    padding: 33px;
    display: flex;
    background: rgba(255,255,255,0.05);
    box-shadow: 0 15px 35px rgb(0 0 0 / 5%);
    border-radius: 20px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255,255,255,0.25);
    border-top: 1px solid rgba(255,255,255,0.5);
    border-left: 1px solid rgba(255,255,255,0.5);
    z-index: 500;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    max-height: 2vh;
    margin-right: 44px;
    /* min-width: 21vh; */
    /* height: 10%; */
    width: auto;
}

.mobile-personal-proj-title h2 {
    font-size: 2em;
    color: white;
    font-family: 'Poppins';
    font-weight: 600;
    /* margin-bottom: 10px; */
}

.contact-resume {
    gap: 20px;
    display: flex;
    flex-direction: row;
    padding: 0px 0px;
}



.mobile-personal-pic {
    background: rgba(255,255,255,0.2);
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);

    position: relative;
    /* padding: 103px; */
    /* background: rgba(255,255,255,0.05); */
    /* box-shadow: 0 10px 30px rgb(255 255 255 / 35%); */
    border-radius: 161px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    /* border: 1px solid rgba(255,255,255,0.25); */
    /* border-top: 1px solid rgba(255,255,255,0.5); */
    /* border-left: 1px solid rgba(255,255,255,0.5); */
    z-index: 500;
    align-content: center;
    /* margin: 0px 20px; */
    height: 100%;
    width: 75%;
    top: 91px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.mobile-personal-pic img {
    width:100%;
    height:100%;
    object-fit:cover;
}

.neumorphism-box {
    background-color: #eff2f9;
    /* width: 100%; */
    padding: 10px 83px;
    color: #2a163f;
    border-radius: 20px;
}

.inner-box {
    background-color: #eff2f9;
    border-radius: 20px;
    box-shadow: inset;
    box-shadow: 10px -5px -5px #fafbff;

}

.mobile-about-box-content a {
    /* color: white; */
    /* color: #e2e2e2; */
    color: #2a163f;
    text-decoration: underline;
    padding: 0px;
}

.mobile-about-box-content a:hover {
    background: none;
    background-color: none;
    box-shadow: none;
    color: white;
    transition: 0.3s;
}

.mobile-about-box-content ul {
    padding: 0px;
    list-style-type: square;
    /* color: #5a486c; */
    color: #2a163f;
    font-size: 1.2em;
}

.mobile-about-box-content li {
    margin-left: 40px;
    padding: 2px 0px;
    /* color: #5a486c; */
    color: #2a163f;
    font-size: 1em;
}


.mobile-about-box-content p {
    font-size: 1.2em;
    /* font-size: calc(10px + 2vmin); */
    /* color: #5a486c; */
    color: #2a163f;

}

.gradient-container {
    position: relative;
    overflow: hidden;
  }
  
  .gradient-container::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: url(https://images.pexels.com/photos/7135057/pexels-photo-7135057.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2) 0 0 repeat;
    /* transform: rotate(180deg); */
    background-size: cover;
  }

  .mobile-about-box-content h2 {
    font-size: calc(14px + 2vmin);
    color: #2a163f;
    font-weight: 600;
}

.main-navigation__current-svg {
    margin-left: 10px;
}

.mobile-navigation__current-svg {
    margin-left: 7px;
    margin-bottom: -1px;
    transform: scale(0.8);
}


/********************************************/

.landing-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 50px;
    /* margin-bottom: -52px; */
    margin-top: 38px;
    width: 40%;
}


.desktop-personal-pic {
    background: rgba(255,255,255,0.2);
    box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
    position: relative;
    /* padding: 103px; */
    /* background: rgba(255,255,255,0.05); */
    /* box-shadow: 0 10px 30px rgb(0,0,0, 20%); */
    border-radius: 300px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    /* border: 1px solid rgba(255,255,255,0.25); */
    /* border-top: 1px solid rgba(255,255,255,0.5); */
    /* border-left: 1px solid rgba(255,255,255,0.5); */
    z-index: 500;
    align-content: center;
    /* margin: 0px 20px; */
    /* height: 100%;
    width: 100%; */
    top: 9rem;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    transform: scale(0.8);
}

.header-h3 {
    font-size: calc(5px + 2vmin);
    font-weight: 600;
    color: #2a163f;
    padding: 0px;
    margin-top: -20px;
    margin-bottom: 0px;
}
