/*************************** NAV BAR ***************************/
.nav-bar-header {
    /* width: 100%;
    height: 100vh;
    background: url('https://i.pinimg.com/564x/10/5c/47/105c478ee7c5f4b74e9145f17e1cd967.jpg');
    background-repeat: none; */
  }
  
  #menu {
    color: #fff;
  cursor: pointer;
  font-size: calc(1vw + 2vmin);
  position: absolute;
  /* right: 10%; */
  /* left: 35px; */
  /* top: 1%; */
  padding: -29px;
  /* margin-right: 1.5em; */
  /* margin-bottom: 10px; */
  /* margin-top: 10px; */
  opacity: 90%;
  z-index: 550;
}

#menu-mobile {
  color: #fff;
  cursor: pointer;
  font-size: calc(1vw + 7vmin);
  position: absolute;
  /* right: 10%; */
  /* left: 35px; */
  /* top: 1%; */
  padding: -29px;
  margin-right: 1em;
  margin-bottom: 10px;
  margin-top: 10px;
  opacity: 90%;
  z-index: 550;
}
  
  #sidenav {
    padding: 100px 20px;
    position: fixed;
    /* width: 250px; */
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.35);
    /* background: #2a163f; */
    /* background: #171717; */
    /* opacity: 97%; */
    backdrop-filter: blur(100px);
    box-shadow: 0 15px 35px rgba(0,0,0,0.05);
    top: 0;
    right: 0;
    z-index: 525;
    animation: growLeft 100ms ease-in-out forwards;
    display: flex;
    justify-content: center;
    
}


@keyframes growLeft {
  0% {
    height: 0%;
  }
  50% {
    height: 80%;
  }
  100% {
    height: 100%;
  }
}
  
  #sidenav ul li {
    list-style: none;
    transition: all .2s ease-in-out; 
    border-bottom: 1px solid rgba(255,255,255,0.5);
    border-color: #2a163f !important ;
    color: #2a163f;
    margin-left: 0px;
    margin-right: 60px;
    padding: 4px 0px;
  }
  
  #sidenav ul li a {
    text-decoration: none;
    color: #2a163f;
    font-size: 2rem;
    margin: 0px 0px;
    font-family: 'Poppins';
    padding: 10px;
    background: none;
    box-shadow: none;
  }

  #sidenav ul li a:hover {
    color: white;
    background: none;
    box-shadow: none;
    transition: 0.3s;
  }

  #sidenav ul li {
    list-style: none;
    transition: all .2s ease-in-out;
    border-bottom: 1px solid rgba(255,255,255,0.5);
    /* border-color: #2a163f; */
    /* margin-left: 0px; */
    margin-left: 0px;
    margin-right: 0px;
    display: flex;
    /* padding: 4px 0px; */
    justify-content: center;
}
  
  #sidenav ul li:hover {
    /* background: rgba(255,255,255,0.4);
    transition: 0.4s */
    /* transform: scale(1.05);  */
    background: none;
    box-shadow: none;
  }

  #sidenav ul {
    margin: 0px;
    display: flex;
    /* justify-content: center; */
    width: 80%;
    flex-direction: column;
  }

/*********************************/
#sidenav-mobile {
  padding: 100px 20px;
  position: fixed;
  width: 100%;
  height: 100%;
  /* background: rgba(255, 255, 255, 0.2); */
  /* background: #171717; */
  background: rgba(0,0,0, 0.85);
  opacity: 97%;
  backdrop-filter: blur(20px);
  box-shadow: 0 15px 35px rgba(0,0,0,0.05);
  top: 0;
  right: 0;
  z-index: 525;
  /* transition: ; */
  /* transition: all .5s ease-in-out; */
  animation: growDown 300ms ease-in-out forwards;

  /* -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s; */
}

@keyframes growDown {
  0% {
    height: 0%;
  }
  50% {
    height: 50%;
  }
  100% {
    height: 100%;
  }
}


#sidenav-mobile ul li {
  list-style: none;
  transition: all .2s ease-in-out; 
  border-bottom: 1px solid rgba(255,255,255,0.5);
  margin-left: 60px;
  margin-right: 60px;
  padding: 4px 0px;
}

#sidenav-mobile ul li a {
  text-decoration: none;
  color: white;
  font-size: 2rem;
  margin: 25px 5px;
  font-family: 'Poppins';
  padding: 10px 0px;
  border-radius: 0;
}

#sidenav-mobile ul li a:hover {
  /* background: rgba(255,255,255,0.4);
  transition: 0.4s */
  /* transform: scale(1.1);  */
  background: none;
  box-shadow: none;
}

#sidenav-mobile ul li:hover {
  /* background: rgba(255,255,255,0.4);
  transition: 0.4s */
  /* transform: scale(1.1);  */
  background: none;
  box-shadow: none;

}

#sidenav-mobile ul {
  margin: 0px -43px;
}

.icon-container {
  display: flex;
  width: 100%;
  /* padding: 100px 0px; */
  position: fixed;
  width: 100%;
  height: 7%;
  /* background: rgba(255, 255, 255, 0.2); */
  /* -webkit-backdrop-filter: blur(30px); */
  /* backdrop-filter: blur(30px); */
  /* box-shadow: 0 15px 35px rgb(0 0 0 / 5%); */
  top: 0;
  right: 0;
  z-index: 550;
  align-items: center;
  justify-content: flex-end;
  /* padding: 1% 0%; */
  padding-right: 3%;
  padding-bottom: 2%;
  /* padding-top: 1%; */
}

.icon-container2 {
  display: flex;
  width: 100%;
  /* padding: 100px 0px; */
  position: fixed;
  width: 100%;
  height: 7%;
  /* background: rgba(255, 255, 255, 0.2); */
  /* -webkit-backdrop-filter: blur(30px); */
  /* backdrop-filter: blur(30px); */
  /* box-shadow: 0 15px 35px rgb(0 0 0 / 5%); */
  top: 0;
  right: 0;
  z-index: 550;
  align-items: center;
  justify-content: flex-start;
  /* padding: 1% 0%; */
  padding-left: 10%;
  padding-bottom: 2%;
  /* padding-top: 1%; */
}

.icon-container2 a {
  font-family: 'Poppins';
    display: flex;
    padding: 5px 10px;
    background: #fff;
    color: #2a163f;

    border-radius: 30px;
    font-size: 1.2em;
    font-weight: 400;
    text-decoration: none;
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    transition: all .2s ease-in-out;
    align-items: center;
    margin-left: 3%;
    opacity: 89%;
}