@import url("https://fonts.googleapis.com/css?family=Niramit:300,300i,700");
/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

/* body {
  font-family: "K2D", sans-serif;
  background: #1e1e2f;
  letter-spacing: 1px;
} */

.container {
  max-width: 650px;
  margin: 50px auto;
}

.container p {
    font-weight: 300;
    line-height: 1.5;
    font-size: 14px;
    font-family: 'Poppins';
    opacity: 0.8;
}

.timeline {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 auto;
  color: white;
}

.timeline-mobile {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 -45px;
  color: white;
}

.timeline:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: #27293d;
}
.timeline .timeline-container {
  position: relative;
  margin-bottom: 2.5rem;
}
.timeline .timeline-container .timeline-icon {
  position: absolute;
  left: -88px;
  top: 0px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  font-size: 2rem;
  background: white;
  padding:2px
;
}
.timeline .timeline-container .timeline-icon i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.timeline .timeline-container .timeline-icon img {
  width: 100%;
  height: 100%;
  border-radius: 20%;
  transform: scale(0.7);
}
.timeline .timeline-container .timeline-body {
  background: #27293d;
  border-radius: 3px;
  padding: 20px 20px 15px;
  /* box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1); */



  /* background:rgba(255,255,255,0.25); */
  box-shadow: 0 15px 35px rgba(0,0,0,0.4);
}

.timeline .timeline-container .timeline-body:before {
  content: "";
  background: inherit;
  width: 20px;
  height: 20px;
  display: block;
  position: absolute;
  left: -10px;
  transform: rotate(45deg);
  border-radius: 0 0 0 2px;
}

.timeline .timeline-container .timeline-body .timeline-title {
  margin-bottom: 1.4rem;
}

.timeline .timeline-container .timeline-body .timeline-title .badge {
  background: none;
  padding: 4px 0px;
  border-radius: 3px;
  font-size: 1.2em;
  font-weight: 500;
  font-family: 'Poppins';
}

.timeline .timeline-container .timeline-body .timeline-subtitle {
  font-weight: 300;
  font-style: italic;
  opacity: 0.4;
  margin-top: 16px;
  font-size: 11px;
}



/* .timeline .timeline-container.primary .badge,
.timeline .timeline-container.primary .timeline-icon {
  background: none !important;
} */
/* .timeline .timeline-container.info .badge,
.timeline .timeline-container.info .timeline-icon {
  background: #11cdef !important;
}
.timeline .timeline-container.success .badge,
.timeline .timeline-container.success .timeline-icon {
  background: #00bf9a !important;
}
.timeline .timeline-container.warning .badge,
.timeline .timeline-container.warning .timeline-icon {
  background: #ff8d72 !important;
}
.timeline .timeline-container.danger .badge,
.timeline .timeline-container.danger .timeline-icon {
  background: #fd5d93 !important;
} */

.author {
  font-family: inherit;
  padding: 3em;
  text-align: center;
  width: 100%;
  color: white;
}
.author a:link,
.author a:visited {
  color: white;
}
.author a:link:hover,
.author a:visited:hover {
  text-decoration: none;
}
.author .btn:link,
.author .btn:visited {
  margin-top: 1em;
  text-decoration: none;
  display: inline-block;
  font-family: inherit;
  font-weight: 100;
  color: white;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: black;
  padding: 1.5em 2rem;
  border-radius: 1em;
  transition: 0.5s all;
}
.author .btn:link:hover, .author .btn:link:focus, .author .btn:link:active,
.author .btn:visited:hover,
.author .btn:visited:focus,
.author .btn:visited:active {
  background-color: #1a1a1a;
}